import { ArrowBack, PersonSearch, Search } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import Newsrchbar from './Mochat.js/Newsrchbar'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Lottie from 'lottie-react'
import lott from './asset/Animation - 1710972521237.json'

function Srch() {


    axios.defaults.withCredentials = true

    const [srchd, setsrchd] = useState([])
    const [srch, setsrch] = useState('')



    const [data, setData] = useState([]);
    const [frndarr, setfrndarr] = useState([]);

    const [sts, setsts] = useState()


    useEffect(() => {

        const fetchData = async () => {
            const value = {
                key: "998877878"
            }
            try {
                await axios.post('https://app.wikiescrow.org/escrow/includes/search.inc.php', value).then((response) => {
                    if (response?.data && response.status === 200) {
                        const filter = response?.data?.filter((val) => {
                            return val.user_name.toLowerCase().includes(srch.toLowerCase())
                        })

                        setData(response.data);
                        setsrchd(filter)
                        setsts(true)
                    } else {
                        setsts(true)
                    }
                });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                await axios.get('https://app.wikiescrow.org/escrow/includes/retrieveFriends.inc.php').then((response) => {
                    if (response.status === 200) {
                        setfrndarr(response.data)
                    }
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const interval = setInterval(() => {
            fetchData();
        }, 1000);

        return () => clearInterval(interval);
    }, [])







    const searchnu = (e) => {
        const scrhword = e.target.value
        setsrch(scrhword)
        if (data) {
            const filter = data.filter((val) => {
                return val.user_name.toLowerCase().includes(scrhword.toLowerCase())
            })

            setsrchd(filter)
        }
    }


    const [bord, setbord] = useState(false)

    const srcclick = () => {
        setbord(true)
    }

    // scroll function starts
    const [bar, setbar] = useState(false)
    const sidebarscroll = (e) => {
        setbar(e.target.scrollTop > 120)
    }
    // scroll function ends



    // theme function starts
    const theme = async () => {
        console.log('yes');
        const color = localStorage.getItem('theme')
        const element = document.getElementById('light');

        if (color === 'dark') {
            element.classList.remove('dark');
            element.classList.add('light');
            localStorage.setItem('theme', 'light')
        }
        else if (color === 'light') {
            element.classList.remove('liht');
            element.classList.add('dark');
            localStorage.setItem('theme', 'dark')
        } else {
            element.classList.remove('liht');
            element.classList.add('dark');
            localStorage.setItem('theme', 'dark')
        }
    }


    // theme function ends


    return (
        <div className='searhBoardContainer searhBoardDesk'>
            <div className="app-header">
                {
                    bar ?
                        <Link to='/' className='linkbkss' >
                            <ArrowBack />
                        </Link>
                        :

                        <div className="app-header-left">
                            <div></div>

                            <a className="app-backlink press" href="https://app.wikiescrow.org/">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 474 474" version="1.1">
                                    <path
                                        d="M 118.294 118.525 L 0.087 236.880 118.710 355.489 L 237.332 474.098 258.159 453.299 L 278.985 432.500 196.245 349.750 L 113.505 267 293.703 267 L 473.902 267 474.201 251.750 C 474.365 243.363, 474.364 229.862, 474.199 221.750 L 473.897 207 293.701 207 L 113.505 207 196.286 124.214 L 279.067 41.428 258.672 21.243 C 247.454 10.141, 238.514 0.819, 238.805 0.529 C 239.095 0.238, 238.696 0.038, 237.917 0.085 C 237.137 0.132, 183.307 53.430, 118.294 118.525"
                                        stroke="none" fillRule="evenodd" />
                                </svg>
                            </a>
                        </div>
                }

                {
                    bar ?
                        <div id="app-pagetitle">Add contact</div>
                        :
                        null
                }


                <div className="app-header-right">
                </div>
            </div>

            <div className='desktoppsrch dsksrch' onScroll={sidebarscroll}>

                <div className='desksidebar desksidebarcontainer desktoppsrchbod'>
                    {
                        bar ?
                            <div className='stickedbar'>
                                <div className={bord ? 'searchinptbord' : 'searchinpt'} >
                                    <Search id='srchh' />
                                    <input onClick={srcclick} value={srch} onChange={searchnu} autoComplete="off" placeholder='Search' />
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className='desksidebarheader'>
                        <div className='mosrchinput'>
                            <div className='deskSidebarBackButton'>
                                <Link to='/' style={{ color: 'black' }} >
                                    <ArrowBack />
                                    <span>Back</span>
                                </Link>
                            </div>
                            <div className='sarrback sarrbackContainer'>
                                <h3>Search</h3>
                            </div>
                            <div className={bord ? 'searchinptbord' : 'searchinpt'} >
                                <Search id='srchh' />
                                <input onClick={srcclick} value={srch} onChange={searchnu} autoComplete="off" placeholder='Enter username' />
                            </div>
                        </div>
                    </div>



                    <div className='mosidebarchats' >
                        {srch !== '' ?
                            <>
                                {sts ?
                                    srchd ?
                                        !srchd?.includes() ?
                                            srchd?.map((data) => (
                                                <Newsrchbar key={data.email} setsrch={setsrch} data={data} />
                                            ))
                                            :
                                            <div>
                                                <p>yes</p>
                                            </div>
                                        : <p>no result</p>
                                    :
                                    <div className='popdicwallpaper popdicwallpaperSearch'>
                                        <Lottie className='ldd' animationData={lott} />
                                    </div>
                                }
                            </>
                            :
                            <div className='emptcont'>
                                <PersonSearch id='gmc' />
                                <p>Search for users</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Srch