import { ArrowBack, NoAccounts, PersonSearch, Search } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { collection, doc, onSnapshot, updateDoc } from 'firebase/firestore'
import Newsrchbar from './Newsrchbar'
import { db } from '../firebase'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectinfo } from '../features/infoslice'
import axios from 'axios'
import Lottie from 'lottie-react'
import lott from '../asset/Animation - 1710972521237.json'
import Cookies from 'js-cookie';
import Maxconnect from '../Deskchat.js/Maxconnect'
import { ToastContainer } from 'react-toastify'

function Adduser() {

    axios.defaults.withCredentials = true

    const [srchd, setsrchd] = useState([])
    const [srch, setsrch] = useState('')



    const [data, setData] = useState([]);
    const [frndarr, setfrndarr] = useState([]);

    const [sts, setsts] = useState()


    useEffect(() => {

        const fetchData = async () => {
            const value = {
                key: process.env.REACT_APP_API_KEY
            }
            try {
                await axios.post('https://app.wikiescrow.org/escrow/includes/search.inc.php', value).then((response) => {
                    if (response.status === 200 && response?.data) {
                        const filter = response?.data?.filter((val) => {
                            return val?.user_name.toLowerCase().includes(srch.toLowerCase())
                        })

                        setData(response.data);
                        setsrchd(filter)
                        setsts(true)
                    } else {
                        setsts(true)
                    }
                });




            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                await axios.get('https://app.wikiescrow.org/escrow/includes/retrieveFriends.inc.php').then((response) => {
                    if (response.status === 200) {
                        setfrndarr(response.data)
                    }
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const interval = setInterval(() => {
            fetchData();
        }, 1000);

        return () => clearInterval(interval);
    }, [])




    const searchnu = (e) => {
        const scrhword = e.target.value
        setsrch(scrhword)
        if (data) {
            const filter = data.filter((val) => {
                return val.user_name.toLowerCase().includes(scrhword.toLowerCase())
            })

            setsrchd(filter)
        }
    }


    const [bord, setbord] = useState(false)

    const srcclick = () => {
        setbord(true)
    }

    // scroll function starts
    const [bar, setbar] = useState(false)
    const sidebarscroll = (e) => {
        setbar(e.target.scrollTop > 120)
    }
    // scroll function ends



    // theme function starts
    const theme = async () => {
        //console.log('yes');
        const color = Cookies.get('theme');
        const element = document.getElementById('light');

        if (color === 'dark') {
            element.classList.remove('dark');
            element.classList.add('light');
            Cookies.set('theme', 'light', { domain: '.wikiescrow.org', path: '/' });
        }
        else if (color === 'light') {
            element.classList.remove('liht');
            element.classList.add('dark');
            Cookies.set('theme', 'dark', { domain: '.wikiescrow.org', path: '/' });
        } else {
            element.classList.remove('liht');
            element.classList.add('dark');
            Cookies.set('theme', 'dark', { domain: '.wikiescrow.org', path: '/' });
        }
    }


    // theme function ends



    return (
        <div className='searhBoardContainer'>
            <ToastContainer />
            <div className="app-header">
                <div className="app-header-left">
                </div>

                {
                    bar ?
                        <div id="app-pagetitle">Add contact</div>
                        :
                        null
                }


                <div className="app-header-right">
                </div>
            </div>

            <div className='desktoppsrch' onScroll={sidebarscroll}>

                <div className='desksidebar desksidebarcontainer desktoppsrchbod'>
                    {
                        bar ?
                            <div className='stickedbar'>
                                <div className={bord ? 'searchinptbord' : 'searchinpt'} >
                                    <Search id='srchh' />
                                    <input onClick={srcclick} value={srch} onChange={searchnu} autoComplete="off" placeholder='Search' />
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className='desksidebarheader'>
                        <div className='mosrchinput'>
                            {/* <div className='deskSidebarBackButton'>
                                <Link to='/' style={{ color: 'black' }} >
                                    <ArrowBack />
                                    <span>Back</span>
                                </Link>
                            </div> */}
                            <div className='sarrback sarrbackContainer'>
                                <h3>Find contact</h3>
                            </div>
                            <div className={bord ? 'searchinptbord' : 'searchinpt'} >
                                <Search id='srchh' />
                                <input onClick={srcclick} value={srch} onChange={searchnu} autoComplete="off" placeholder='Enter username' />
                            </div>
                        </div>
                    </div>


                    <Maxconnect>
                        <div className='mosidebarchats' >
                            {srch !== '' ?
                                <>
                                    {sts ?
                                        srchd ?
                                            srchd?.length > 0 ?
                                                srchd?.map((data) => (
                                                    <Newsrchbar frndarr={frndarr} key={data.serial} setsrch={setsrch} data={data} />
                                                ))
                                                :
                                                <div className='emptcont'>
                                                    <NoAccounts id='gmc' />
                                                    <p>no result</p>
                                                </div>
                                            : null
                                        :
                                        <div className='popdicwallpaper popdicwallpaperSearch'>
                                            <Lottie className='ldd' animationData={lott} />
                                        </div>
                                    }
                                </>
                                :
                                <div className='emptcont'>
                                    <PersonSearch id='gmc' />
                                    <p>Search for users</p>
                                </div>
                            }
                        </div>
                    </Maxconnect>
                </div>
            </div>
        </div>
    )
}

export default Adduser