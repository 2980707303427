import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { frnd } from '../features/frndSlice'
import { selectinfo } from '../features/infoslice'
import axios from 'axios'

function Srchbar({ data, setrch, setdetectmsg, fetchData, fetchData1, setmsgrl, setmsgr2, setmsgr3, setmsgr4, setmsgr5, setpop, }) {

  axios.defaults.withCredentials = true
  const user = useSelector(selectinfo)

  const dispatch = useDispatch()


  const addfriend = async () => {
    fetchData()
    fetchData1()
    setdetectmsg('trust')

    const arr = {
      image: `svg${data?.image % 10}`,
      combined_id: data?.combined_id,
      name: data?.name,
      email: data?.email,
      username: data?.username,
      eth: data?.eth_address,
      btc: data?.btc_address,
      notification: data?.notification,
      usertype: data?.usertype,
    }

    dispatch(
      frnd({
        id: arr,
      })
    )
    setrch('')

    const value = {
      combined_id: data?.combined_id
    }

    try {
      await axios.post('https://app.wikiescrow.org/escrow/includes/clearMessageAlert.inc.php', value).then((response) => {
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }












  return (
    <>
      {
        data?.email === user?.user?.email ?
          null
          :
          <div onClick={addfriend} className='sidebars'>
            <div className='avatarbod'>
              <div className={`svg${data?.image % 10}`}></div>
            </div>
            {/* <div className='userStatusWrapper'>{timer ? <div className='userOnlineStatus'></div> : <div className='userOfflineStatus'></div>}</div> */}
            <div className='sidebarwrt' >
              <h3>{data?.name}</h3>
              <p>@{data?.username}</p>
            </div>
          </div>
      }
    </>
  )
}

export default Srchbar