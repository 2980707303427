import { createSlice } from '@reduxjs/toolkit';

export const frndSlice = createSlice({
  name: 'frnd',
  initialState: {
    frnd: null,
  },
  reducers: {
    frnd: (state, action) => {
      state.frnd = action.payload;
    },
  }
});

export const { frnd } = frndSlice.actions;

export const selectfrnd = (state) => state.frnd.frnd;

export default frndSlice.reducer;
