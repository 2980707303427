import React, { useEffect, useState } from 'react'
import './mochat.css'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { info } from '../features/infoslice'
import Bottom from '../Bottom'
import Profile from '../Deskchat.js/Profile'
import Setting from '../Deskchat.js/Setting'
import Notification from '../Deskchat.js/Notification'
import Adduser from './Adduser'
import Srch from '../Srch'
import Global from '../Global'
import Mobchatbod from './Mobchatbod'
import About from './About'
import Pincode from './Pincode'
import Code from './Code'
import Reset from './Reset'
import axios from 'axios'
import Preloasderr from './Preloasderr'


function Mobchat({ data, setload }) {


  axios.defaults.withCredentials = true


  const dispatch = useDispatch()



  useEffect(() => {
    const func = async () => {
      try {
        const arr = {
          email: `${data?.email}`,
          name: `${data?.name}`,
          image: `${data?.image}`,
          notificati: `${data?.notification}`,
          balance: `${data?.balance}`,
          usertype: `${data?.userType}`,
          ref_code: `${data?.referral_code}`,
          btc: `${data?.btcAddress}`,
          eth: `${data?.ethAddress}`,
          username: `${data?.username}`,
          num: 0
        }

        dispatch(
          info({
            user: arr,
          })
        )
      } catch (e) {

      }
    }

    let int = setInterval(() => {
      func()
    }, 1000);

    return () => {
      clearInterval(int)
    }
  }, [])











  // dispatching user info ends 

  const [vbarn, setvbar] = useState(true)


  const pin = localStorage.getItem('pincode')
  const check = sessionStorage.getItem('key')

  const [ref2, setref2] = useState('0')



  useEffect(() => {
    const func = async () => {
      try {
        await axios.post('https://app.wikiescrow.org/escrow/includes/updateOnlineStatus.inc.php').then((response) => {
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    const interval = setInterval(func, 10000);
    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [])

  return (
    <Router>
      {
        pin ?
          check ?
            <div className='rootchild'>
              <Routes>
                <Route path='/' element={<Profile ref2={ref2} setref2={setref2} setvbar={setvbar} />} />
                <Route path='/search' element={<Srch />} />
                <Route path='/profile' element={<Preloasderr />} />
                <Route path='/adduser' element={<Adduser ref2={ref2} setref2={setref2} />} />
                <Route path='/chatbox' element={<Mobchatbod ref2={ref2} setref2={setref2} setvbar={setvbar} />} />
                <Route path='/chat' element={<Global setvbar={setvbar} setref2={setref2} />} />
                <Route path='/notification' element={<Notification ref2={ref2} setref2={setref2} />} />
                <Route path='/settings' element={<Setting ref2={ref2} setref2={setref2} setvbar={setvbar} />} />
                <Route path='/aboutus' element={<About setvbar={setvbar} />} />

              </Routes>

              {
                vbarn ?
                  <div className='bottomBarHeight'></div>
                  : null
              }
              {
                vbarn ?
                  <Bottom ref2={ref2} setref2={setref2} />
                  : null
              }
            </div>
            :
            <>
              <Routes>
                <Route path='/' element={<Code />} />
                <Route path='/reset' element={<Reset />} />
              </Routes>
            </>
          :
          <>

            <Pincode />
          </>
      }


    </Router>
  )
}

export default Mobchat