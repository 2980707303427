import React, { useEffect } from 'react'
import Mobhome from './Mochat.js/Mobhome'
import Deskchat from './Deskchat.js/Deskchat'

function Global({ setvbar, setref2 }) {

    useEffect(() => {
        localStorage.setItem('pos', 1)
        localStorage.setItem('pos1', 25)
        setref2('25%')
    }, [])

    return (
        < >
            <Mobhome setvbar={setvbar} />
            <Deskchat setvbar={setvbar} />
        </>
    )
}

export default Global