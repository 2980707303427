import Lottie from 'lottie-react'
import React, { useEffect, useState } from 'react'
import { Detector } from 'react-detect-offline'
import lott from '../asset/Animation - 1710972521237.json'

function Connection(props) {

    const [detectdesk, setdetectdesk] = useState()
    const [detectand, setdetectand] = useState()
    const [detectios, setdetectios] = useState()


    useEffect(() => {
        // Check if the device is a laptop
        setdetectdesk(window.matchMedia('(min-width: 999px)').matches)

        // Check if the device is an Android device
        setdetectand(/Android/i.test(navigator.userAgent))

        // Check if the device is an iOS device
        setdetectios(/iPhone|iPad|iPod/i.test(navigator.userAgent))

        var isMacOS = navigator.userAgent.match(/Macintosh|Mac\s+OS\s+X/i) !== null;

        // Check if isMacOS is true
        if (isMacOS) {
            // Check if the browser supports touch events
            var isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

            // Check if isTouchScreen is true
            if (isTouchScreen) {
                // Device likely iPad Air
                setdetectios(true)
            }

        }
    }, [])

    return (
        <>
            {
                detectand ?
                    <div>
                        <Detector
                            render={({ online }) => (
                                online ?
                                    props.children
                                    :
                                    <div className='lot'>
                                        {
                                            detectand ?
                                                <>
                                                    <div className='loader smlld'>
                                                        <svg className="circular" viewBox="25 25 50 50">
                                                            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10" />
                                                        </svg>
                                                    </div>
                                                    connecting...
                                                </>
                                                : null
                                        }


                                    </div>
                            )}
                        />
                    </div>
                    :
                    null
            }

            {
                detectios ?
                    <div>
                        <Detector
                            render={({ online }) => (
                                online ?
                                    props.children
                                    :
                                    <div className='lot'>

                                        <>
                                            <div className="floatingBarsBase smlld">
                                                <div className="floatingBarsG">
                                                    <div className="blockG" id="rotateG_01"></div>
                                                    <div className="blockG" id="rotateG_02"></div>
                                                    <div className="blockG" id="rotateG_03"></div>
                                                    <div className="blockG" id="rotateG_04"></div>
                                                    <div className="blockG" id="rotateG_05"></div>
                                                    <div className="blockG" id="rotateG_06"></div>
                                                    <div className="blockG" id="rotateG_07"></div>
                                                    <div className="blockG" id="rotateG_08"></div>
                                                </div>
                                            </div>
                                            connecting...
                                        </>
                                    </div>
                            )}
                        />
                    </div>
                    :
                    null
            }
        </>
    )
}

export default Connection