import React from 'react'
import ReactTimeAgo from 'react-time-ago'

function Notslip({ val }) {

    const milliseconds = val?.message_date * 1000;

    // Create a new Date object with the milliseconds
    const dateObject = new Date(milliseconds);

    return (
        <div className='notification-container'>
            <header className='notification-header'>
                {
                    val?.user_email.toLowerCase() === 'general' ?
                        <h2>Announcement</h2>
                        :
                        <h2>Personal</h2>
                }
                <span className='notification-timestamp'><ReactTimeAgo date={dateObject} locale='en-US' /></span>
            </header>

            <div className='notification-content'>
                <span className='notification-message'>{val?.message}</span>
            </div>
        </div>
    )
}

export default Notslip