import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import "firebase/compat/auth";
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: "starflytower.firebaseapp.com",
    projectId: "starflytower",
    storageBucket: "starflytower.appspot.com",
    databaseURL: "https://starflytower-default-rtdb.firebaseio.com",
    messagingSenderId: "164219196887",
    appId: "1:164219196887:web:336777453eadab4346e792",
    measurementId: "G-PK8DHK609L"
};




const app = firebase.initializeApp(firebaseConfig)

export const db = app.firestore()

export const auth = app.auth()

export const storage = getStorage(app)

export const dbauth = getAuth(app)

export const Provider = new firebase.auth.GoogleAuthProvider()


const apk = firebase.initializeApp(firebaseConfig)

export default apk