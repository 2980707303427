import React from 'react'
import './loader.css'

function Loader({ detectand, detectdesk, detectios }) {
    return (
        <div className="prlw" >
            {
                detectand ?
                    <div className='loader loader38'>
                        <svg className="circular" viewBox="25 25 50 50">
                            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10" />
                        </svg>
                    </div>
                    :
                    null
            }


            {
                detectios ?
                    <div className="floatingBarsBase iosfloating38">
                        <div className="floatingBarsG">
                            <div className="blockG" id="rotateG_01"></div>
                            <div className="blockG" id="rotateG_02"></div>
                            <div className="blockG" id="rotateG_03"></div>
                            <div className="blockG" id="rotateG_04"></div>
                            <div className="blockG" id="rotateG_05"></div>
                            <div className="blockG" id="rotateG_06"></div>
                            <div className="blockG" id="rotateG_07"></div>
                            <div className="blockG" id="rotateG_08"></div>
                        </div>
                    </div>
                    :
                    null
            }

        </div>
    )
}

export default Loader