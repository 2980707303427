import React, { useEffect, useState } from 'react'
import './pincode.css'
import { Link } from 'react-router-dom';

function Code() {

    const calculateTimeLeft = (targetDate) => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const getTargetDateFromStorage = () => {
        const storedTargetDate = localStorage.getItem('timer');
        return storedTargetDate ? new Date(storedTargetDate) : null;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(getTargetDateFromStorage()));

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft(getTargetDateFromStorage()));
        }, 1000);

        return () => clearTimeout(timer);
    });

    const formatTime = (time) => {
        return time < 10 ? `0${time}` : time;
    };

    // Update the countdown timer when the user comes back online

    useEffect(() => {
        const handleOnline = () => {
            setTimeLeft(calculateTimeLeft(getTargetDateFromStorage()));
        };

        window.addEventListener('online', handleOnline);

        return () => window.removeEventListener('online', handleOnline);
    }, []);

    const hours = formatTime(timeLeft.hours);
    const minutes = formatTime(timeLeft.minutes);
    const seconds = formatTime(timeLeft.seconds);


    const [check, setcheck] = useState()
    let get = localStorage.getItem('trials')

    useEffect(() => {
        const func = () => {
            const isCountdownEnded = (timeLeft) => {
                return Object.values(timeLeft).every(value => value === 0);
            };

            // Example usage
            const timeLeft = calculateTimeLeft(getTargetDateFromStorage());
            const countdownEnded = isCountdownEnded(timeLeft);

            let timer = localStorage.getItem('timer')
            if (countdownEnded && get && timer) {
                localStorage.removeItem('trials')
                localStorage.removeItem('timer')
            }
            setcheck(countdownEnded)
        }

        setInterval(() => {
            func()
        }, 80);
    }, [])


    const [val, setval] = useState('')

    const fst = () => {

        if (val.length < 4) {
            if (val.length === 0) {
                document.getElementById("fstdot").classList.add("pinCodeActive");
            } else if (val.length === 1) {
                document.getElementById("secdot").classList.add("pinCodeActive");
            } else if (val.length === 2) {
                document.getElementById("trddot").classList.add("pinCodeActive");
            } else if (val.length === 3) {
                document.getElementById("frtdot").classList.add("pinCodeActive");
            }
            if (val.length === 3) {
                const pin = localStorage.getItem('pincode')
                setval(val + '1')
                if (pin === val + '1') {
                    localStorage.removeItem('trials')
                    //document.getElementById("lockNumbers").classList.add("lockNumbersHide");
                    console.log("Welldone");
                    sessionStorage.setItem('key', 'value');
                    window.location.reload();
                } else {
                    setval('')


                    if (get && get !== '5') {
                        localStorage.setItem('trials', parseInt(get) + 1)
                    } else if (!get && get !== '5') {
                        localStorage.setItem('trials', 1)
                    } else if (get && 5 - parseInt(get) === 0) {
                        console.log('too many atempt')
                        const currentDate = new Date();

                        // Add 24 hours to the current date and time
                        const targetDate = new Date(currentDate.getTime() + 10 * 60 * 1000);

                        // Convert the resulting date object to ISO 8601 format
                        const isoString = targetDate.toISOString();

                        localStorage.setItem('timer', isoString)
                    }


                    document.getElementById("lockScreenFields").classList.add("miss");

                    setTimeout(function () {
                        document.getElementById("fstdot").classList.remove("pinCodeActive");
                        document.getElementById("secdot").classList.remove("pinCodeActive");
                        document.getElementById("trddot").classList.remove("pinCodeActive");
                        document.getElementById("frtdot").classList.remove("pinCodeActive");
                    }, 200);
                    setTimeout(function () {
                        document.getElementById("lockScreenFields").classList.remove("miss");
                    }, 500);
                }
            } else {
                setval(val + '1')
            }
        }
    }

    const sec = () => {

        if (val.length < 4) {
            if (val.length === 0) {
                document.getElementById("fstdot").classList.add("pinCodeActive");
            } else if (val.length === 1) {
                document.getElementById("secdot").classList.add("pinCodeActive");
            } else if (val.length === 2) {
                document.getElementById("trddot").classList.add("pinCodeActive");
            } else if (val.length === 3) {
                document.getElementById("frtdot").classList.add("pinCodeActive");
            }
            if (val.length === 3) {
                const pin = localStorage.getItem('pincode')
                setval(val + '2')
                if (pin === val + '2') {
                    localStorage.removeItem('trials')
                    //document.getElementById("lockNumbers").classList.add("lockNumbersHide");
                    console.log("Welldone");
                    sessionStorage.setItem('key', 'value');
                    window.location.reload();
                } else {
                    setval('')

                    if (get && get !== '5') {
                        localStorage.setItem('trials', parseInt(get) + 1)
                    } else if (!get && get !== '5') {
                        localStorage.setItem('trials', 1)
                    } else if (get && 5 - parseInt(get) === 0) {
                        console.log('too many atempt')
                        const currentDate = new Date();

                        // Add 24 hours to the current date and time
                        const targetDate = new Date(currentDate.getTime() + 10 * 60 * 1000);

                        // Convert the resulting date object to ISO 8601 format
                        const isoString = targetDate.toISOString();

                        localStorage.setItem('timer', isoString)
                    }

                    document.getElementById("lockScreenFields").classList.add("miss");

                    setTimeout(function () {
                        document.getElementById("fstdot").classList.remove("pinCodeActive");
                        document.getElementById("secdot").classList.remove("pinCodeActive");
                        document.getElementById("trddot").classList.remove("pinCodeActive");
                        document.getElementById("frtdot").classList.remove("pinCodeActive");
                    }, 200);
                    setTimeout(function () {
                        document.getElementById("lockScreenFields").classList.remove("miss");
                    }, 500);
                }
            } else {
                setval(val + '2')
            }
        }
    }

    const trd = () => {

        if (val.length < 4) {
            if (val.length === 0) {
                document.getElementById("fstdot").classList.add("pinCodeActive");
            } else if (val.length === 1) {
                document.getElementById("secdot").classList.add("pinCodeActive");
            } else if (val.length === 2) {
                document.getElementById("trddot").classList.add("pinCodeActive");
            } else if (val.length === 3) {
                document.getElementById("frtdot").classList.add("pinCodeActive");
            }
            if (val.length === 3) {
                const pin = localStorage.getItem('pincode')
                setval(val + '3')
                if (pin === val + '3') {
                    localStorage.removeItem('trials')
                    //document.getElementById("lockNumbers").classList.add("lockNumbersHide");
                    console.log("Welldone");
                    sessionStorage.setItem('key', 'value');
                    window.location.reload();
                } else {
                    setval('')

                    if (get && get !== '5') {
                        localStorage.setItem('trials', parseInt(get) + 1)
                    } else if (!get && get !== '5') {
                        localStorage.setItem('trials', 1)
                    } else if (get && 5 - parseInt(get) === 0) {
                        console.log('too many atempt')
                        const currentDate = new Date();

                        // Add 24 hours to the current date and time
                        const targetDate = new Date(currentDate.getTime() + 10 * 60 * 1000);

                        // Convert the resulting date object to ISO 8601 format
                        const isoString = targetDate.toISOString();

                        localStorage.setItem('timer', isoString)
                    }

                    document.getElementById("lockScreenFields").classList.add("miss");

                    setTimeout(function () {
                        document.getElementById("fstdot").classList.remove("pinCodeActive");
                        document.getElementById("secdot").classList.remove("pinCodeActive");
                        document.getElementById("trddot").classList.remove("pinCodeActive");
                        document.getElementById("frtdot").classList.remove("pinCodeActive");
                    }, 200);
                    setTimeout(function () {
                        document.getElementById("lockScreenFields").classList.remove("miss");
                    }, 500);
                }
            } else {
                setval(val + '3')
            }
        }

    }

    const frt = () => {

        if (val.length < 4) {
            if (val.length === 0) {
                document.getElementById("fstdot").classList.add("pinCodeActive");
            } else if (val.length === 1) {
                document.getElementById("secdot").classList.add("pinCodeActive");
            } else if (val.length === 2) {
                document.getElementById("trddot").classList.add("pinCodeActive");
            } else if (val.length === 3) {
                document.getElementById("frtdot").classList.add("pinCodeActive");
            }
            if (val.length === 3) {
                const pin = localStorage.getItem('pincode')
                setval(val + '4')
                if (pin === val + '4') {
                    localStorage.removeItem('trials')
                    //document.getElementById("lockNumbers").classList.add("lockNumbersHide");
                    console.log("Welldone");
                    sessionStorage.setItem('key', 'value');
                    window.location.reload();
                } else {
                    setval('')

                    if (get && get !== '5') {
                        localStorage.setItem('trials', parseInt(get) + 1)
                    } else if (!get && get !== '5') {
                        localStorage.setItem('trials', 1)
                    } else if (get && 5 - parseInt(get) === 0) {
                        console.log('too many atempt')
                        const currentDate = new Date();

                        // Add 24 hours to the current date and time
                        const targetDate = new Date(currentDate.getTime() + 10 * 60 * 1000);

                        // Convert the resulting date object to ISO 8601 format
                        const isoString = targetDate.toISOString();

                        localStorage.setItem('timer', isoString)
                    }

                    document.getElementById("lockScreenFields").classList.add("miss");

                    setTimeout(function () {
                        document.getElementById("fstdot").classList.remove("pinCodeActive");
                        document.getElementById("secdot").classList.remove("pinCodeActive");
                        document.getElementById("trddot").classList.remove("pinCodeActive");
                        document.getElementById("frtdot").classList.remove("pinCodeActive");
                    }, 200);
                    setTimeout(function () {
                        document.getElementById("lockScreenFields").classList.remove("miss");
                    }, 500);
                }
            } else {
                setval(val + '4')
            }
        }

    }

    const fif = () => {

        if (val.length < 4) {
            if (val.length === 0) {
                document.getElementById("fstdot").classList.add("pinCodeActive");
            } else if (val.length === 1) {
                document.getElementById("secdot").classList.add("pinCodeActive");
            } else if (val.length === 2) {
                document.getElementById("trddot").classList.add("pinCodeActive");
            } else if (val.length === 3) {
                document.getElementById("frtdot").classList.add("pinCodeActive");
            }
            if (val.length === 3) {
                const pin = localStorage.getItem('pincode')
                setval(val + '5')
                if (pin === val + '5') {
                    localStorage.removeItem('trials')
                    //document.getElementById("lockNumbers").classList.add("lockNumbersHide");
                    console.log("Welldone");
                    sessionStorage.setItem('key', 'value');
                    window.location.reload();
                } else {
                    setval('')

                    if (get && get !== '5') {
                        localStorage.setItem('trials', parseInt(get) + 1)
                    } else if (!get && get !== '5') {
                        localStorage.setItem('trials', 1)
                    } else if (get && 5 - parseInt(get) === 0) {
                        console.log('too many atempt')
                        const currentDate = new Date();

                        // Add 24 hours to the current date and time
                        const targetDate = new Date(currentDate.getTime() + 10 * 60 * 1000);

                        // Convert the resulting date object to ISO 8601 format
                        const isoString = targetDate.toISOString();

                        localStorage.setItem('timer', isoString)
                    }

                    document.getElementById("lockScreenFields").classList.add("miss");

                    setTimeout(function () {
                        document.getElementById("fstdot").classList.remove("pinCodeActive");
                        document.getElementById("secdot").classList.remove("pinCodeActive");
                        document.getElementById("trddot").classList.remove("pinCodeActive");
                        document.getElementById("frtdot").classList.remove("pinCodeActive");
                    }, 200);
                    setTimeout(function () {
                        document.getElementById("lockScreenFields").classList.remove("miss");
                    }, 500);
                }
            } else {
                setval(val + '5')
            }
        }
    }

    const six = () => {

        if (val.length < 4) {
            if (val.length === 0) {
                document.getElementById("fstdot").classList.add("pinCodeActive");
            } else if (val.length === 1) {
                document.getElementById("secdot").classList.add("pinCodeActive");
            } else if (val.length === 2) {
                document.getElementById("trddot").classList.add("pinCodeActive");
            } else if (val.length === 3) {
                document.getElementById("frtdot").classList.add("pinCodeActive");
            }
            if (val.length === 3) {
                const pin = localStorage.getItem('pincode')
                setval(val + '6')
                if (pin === val + '6') {
                    localStorage.removeItem('trials')
                    //document.getElementById("lockNumbers").classList.add("lockNumbersHide");
                    console.log("Welldone");
                    sessionStorage.setItem('key', 'value');
                    window.location.reload();
                } else {
                    setval('')

                    if (get && get !== '5') {
                        localStorage.setItem('trials', parseInt(get) + 1)
                    } else if (!get && get !== '5') {
                        localStorage.setItem('trials', 1)
                    } else if (get && 5 - parseInt(get) === 0) {
                        console.log('too many atempt')
                        const currentDate = new Date();

                        // Add 24 hours to the current date and time
                        const targetDate = new Date(currentDate.getTime() + 10 * 60 * 1000);

                        // Convert the resulting date object to ISO 8601 format
                        const isoString = targetDate.toISOString();

                        localStorage.setItem('timer', isoString)
                    }

                    document.getElementById("lockScreenFields").classList.add("miss");

                    setTimeout(function () {
                        document.getElementById("fstdot").classList.remove("pinCodeActive");
                        document.getElementById("secdot").classList.remove("pinCodeActive");
                        document.getElementById("trddot").classList.remove("pinCodeActive");
                        document.getElementById("frtdot").classList.remove("pinCodeActive");
                    }, 200);
                    setTimeout(function () {
                        document.getElementById("lockScreenFields").classList.remove("miss");
                    }, 500);

                }
            } else {
                setval(val + '6')
            }
        }
    }

    const sev = () => {

        if (val.length < 4) {
            if (val.length === 0) {
                document.getElementById("fstdot").classList.add("pinCodeActive");
            } else if (val.length === 1) {
                document.getElementById("secdot").classList.add("pinCodeActive");
            } else if (val.length === 2) {
                document.getElementById("trddot").classList.add("pinCodeActive");
            } else if (val.length === 3) {
                document.getElementById("frtdot").classList.add("pinCodeActive");
            }
            if (val.length === 3) {
                const pin = localStorage.getItem('pincode')
                setval(val + '7')
                if (pin === val + '7') {
                    localStorage.removeItem('trials')
                    //document.getElementById("lockNumbers").classList.add("lockNumbersHide");
                    console.log("Welldone");
                    sessionStorage.setItem('key', 'value');
                    window.location.reload();
                } else {
                    setval('')

                    if (get && get !== '5') {
                        localStorage.setItem('trials', parseInt(get) + 1)
                    } else if (!get && get !== '5') {
                        localStorage.setItem('trials', 1)
                    } else if (get && 5 - parseInt(get) === 0) {
                        console.log('too many atempt')
                        const currentDate = new Date();

                        // Add 24 hours to the current date and time
                        const targetDate = new Date(currentDate.getTime() + 10 * 60 * 1000);

                        // Convert the resulting date object to ISO 8601 format
                        const isoString = targetDate.toISOString();

                        localStorage.setItem('timer', isoString)
                    }

                    document.getElementById("lockScreenFields").classList.add("miss");

                    setTimeout(function () {
                        document.getElementById("fstdot").classList.remove("pinCodeActive");
                        document.getElementById("secdot").classList.remove("pinCodeActive");
                        document.getElementById("trddot").classList.remove("pinCodeActive");
                        document.getElementById("frtdot").classList.remove("pinCodeActive");
                    }, 200);
                    setTimeout(function () {
                        document.getElementById("lockScreenFields").classList.remove("miss");
                    }, 500);
                }
            } else {
                setval(val + '7')
            }
        }
    }

    const eit = () => {

        if (val.length < 4) {
            if (val.length === 0) {
                document.getElementById("fstdot").classList.add("pinCodeActive");
            } else if (val.length === 1) {
                document.getElementById("secdot").classList.add("pinCodeActive");
            } else if (val.length === 2) {
                document.getElementById("trddot").classList.add("pinCodeActive");
            } else if (val.length === 3) {
                document.getElementById("frtdot").classList.add("pinCodeActive");
            }
            if (val.length === 3) {
                const pin = localStorage.getItem('pincode')
                setval(val + '8')
                if (pin === val + '8') {
                    localStorage.removeItem('trials')
                    //document.getElementById("lockNumbers").classList.add("lockNumbersHide");
                    console.log("Welldone");
                    sessionStorage.setItem('key', 'value');
                    window.location.reload();
                } else {
                    setval('')

                    if (get && get !== '5') {
                        localStorage.setItem('trials', parseInt(get) + 1)
                    } else if (!get && get !== '5') {
                        localStorage.setItem('trials', 1)
                    } else if (get && 5 - parseInt(get) === 0) {
                        console.log('too many atempt')
                        const currentDate = new Date();

                        // Add 24 hours to the current date and time
                        const targetDate = new Date(currentDate.getTime() + 10 * 60 * 1000);

                        // Convert the resulting date object to ISO 8601 format
                        const isoString = targetDate.toISOString();

                        localStorage.setItem('timer', isoString)
                    }

                    document.getElementById("lockScreenFields").classList.add("miss");

                    setTimeout(function () {
                        document.getElementById("fstdot").classList.remove("pinCodeActive");
                        document.getElementById("secdot").classList.remove("pinCodeActive");
                        document.getElementById("trddot").classList.remove("pinCodeActive");
                        document.getElementById("frtdot").classList.remove("pinCodeActive");
                    }, 200);
                    setTimeout(function () {
                        document.getElementById("lockScreenFields").classList.remove("miss");
                    }, 500);
                }
            } else {
                setval(val + '8')
            }
        }
    }

    const nin = () => {

        if (val.length < 4) {
            if (val.length === 0) {
                document.getElementById("fstdot").classList.add("pinCodeActive");
            } else if (val.length === 1) {
                document.getElementById("secdot").classList.add("pinCodeActive");
            } else if (val.length === 2) {
                document.getElementById("trddot").classList.add("pinCodeActive");
            } else if (val.length === 3) {
                document.getElementById("frtdot").classList.add("pinCodeActive");
            }
            if (val.length === 3) {
                const pin = localStorage.getItem('pincode')
                setval(val + '9')
                if (pin === val + '9') {
                    localStorage.removeItem('trials')
                    //document.getElementById("lockNumbers").classList.add("lockNumbersHide");
                    console.log("Welldone");
                    sessionStorage.setItem('key', 'value');
                    window.location.reload();
                } else {
                    setval('')

                    if (get && get !== '5') {
                        localStorage.setItem('trials', parseInt(get) + 1)
                    } else if (!get && get !== '5') {
                        localStorage.setItem('trials', 1)
                    } else if (get && 5 - parseInt(get) === 0) {
                        console.log('too many atempt')
                        const currentDate = new Date();

                        // Add 24 hours to the current date and time
                        const targetDate = new Date(currentDate.getTime() + 10 * 60 * 1000);

                        // Convert the resulting date object to ISO 8601 format
                        const isoString = targetDate.toISOString();

                        localStorage.setItem('timer', isoString)
                    }

                    document.getElementById("lockScreenFields").classList.add("miss");

                    setTimeout(function () {
                        document.getElementById("fstdot").classList.remove("pinCodeActive");
                        document.getElementById("secdot").classList.remove("pinCodeActive");
                        document.getElementById("trddot").classList.remove("pinCodeActive");
                        document.getElementById("frtdot").classList.remove("pinCodeActive");
                    }, 200);
                    setTimeout(function () {
                        document.getElementById("lockScreenFields").classList.remove("miss");
                    }, 500);
                }
            } else {
                setval(val + '9')
            }
        }
    }

    const ten = () => {

        if (val.length < 4) {
            if (val.length === 0) {
                document.getElementById("fstdot").classList.add("pinCodeActive");
            } else if (val.length === 1) {
                document.getElementById("secdot").classList.add("pinCodeActive");
            } else if (val.length === 2) {
                document.getElementById("trddot").classList.add("pinCodeActive");
            } else if (val.length === 3) {
                document.getElementById("frtdot").classList.add("pinCodeActive");
            }
            if (val.length === 3) {
                const pin = localStorage.getItem('pincode')
                setval(val + '0')
                if (pin === val + '0') {
                    localStorage.removeItem('trials')
                    //document.getElementById("lockNumbers").classList.add("lockNumbersHide");
                    console.log("Welldone");
                    sessionStorage.setItem('key', 'value');
                    window.location.reload();
                } else {
                    setval('')

                    if (get && get !== '5') {
                        localStorage.setItem('trials', parseInt(get) + 1)
                    } else if (!get && get !== '5') {
                        localStorage.setItem('trials', 1)
                    } else if (get && 5 - parseInt(get) === 0) {
                        console.log('too many atempt')
                        const currentDate = new Date();

                        // Add 24 hours to the current date and time
                        const targetDate = new Date(currentDate.getTime() + 10 * 60 * 1000);

                        // Convert the resulting date object to ISO 8601 format
                        const isoString = targetDate.toISOString();

                        localStorage.setItem('timer', isoString)
                    }

                    document.getElementById("lockScreenFields").classList.add("miss");

                    setTimeout(function () {
                        document.getElementById("fstdot").classList.remove("pinCodeActive");
                        document.getElementById("secdot").classList.remove("pinCodeActive");
                        document.getElementById("trddot").classList.remove("pinCodeActive");
                        document.getElementById("frtdot").classList.remove("pinCodeActive");
                    }, 200);
                    setTimeout(function () {
                        document.getElementById("lockScreenFields").classList.remove("miss");
                    }, 500);
                }
            } else {
                setval(val + '0')
            }
        }
    }

    const clear = () => {
        if (val.length > 0) {
            setval(val.slice(0, -1))
            if (val.length === 1) {
                document.getElementById("fstdot").classList.remove("pinCodeActive");
            } else if (val.length === 2) {
                document.getElementById("secdot").classList.remove("pinCodeActive");
            } else if (val.length === 3) {
                document.getElementById("trddot").classList.remove("pinCodeActive");
            } else if (val.length === 4) {
                document.getElementById("frtdot").classList.remove("pinCodeActive");
            }
        }
    }

    let left = localStorage.getItem('trials')


    return (
        <div id="pincode">
            <div className="lockcell">

                <svg xmlns="http://www.w3.org/2000/svg" className="screenPadlockSvg" viewBox="0 0 904 904">
                    <path
                        d="m427 11.7-13.5 1.9a237 237 0 0 0-71.9 22.9 249 249 0 0 0-131.5 169.8c-4.2 19.5-4.4 22-5 89.7l-.6 66.4-7 1.2a72 72 0 0 0-60.7 50.5l-2.3 6.4v416l2.3 6.5c9 25 27 42 51 48 7.5 2 12.3 2 263.7 2s256.1 0 264.3-2a70 70 0 0 0 53.1-60.7c.6-5.3.8-87.4.7-208.3-.3-178-.5-200.1-2-205.1a71 71 0 0 0-37-45.4c-9-4.3-19.5-7.2-27.1-7.7l-4-.3-.6-65c-.5-52.4-1-67-2.2-75.1a245 245 0 0 0-74-144 248 248 0 0 0-112.6-62 218 218 0 0 0-58.6-6.3 476 476 0 0 0-24.5.6m6.1 70.4a177 177 0 0 0-154 139.7c-3.5 16.7-4.2 33.8-3.9 89l.3 52.3h176c96.7-.1 176-.3 176.4-.6 1-1 .3-114.2-.9-124.5A176 176 0 0 0 433 82.1"
                        fillRule="evenodd" />
                </svg>
                <div id="locktext">
                    {
                        check ?
                            !left ?
                                <p>Enter your passcode</p>
                                :
                                //5 - parseInt(left) !== 0 ?
                                <p>Incorrect passcode<br /> You have {6 - parseInt(left)} attempts left </p>
                            //:
                            //<p>last attempt</p>
                            :
                            minutes || seconds ?
                                <p>Try again in {minutes + ':' + seconds}</p>
                                :
                                null
                    }
                </div>

                <div id="lockScreenFields">
                    <div className="lockgrid">
                        <div className="lockGridCol lockInputBox lockNumbfield " id='fstdot'><span></span></div>
                        <div className="lockGridCol lockInputBox lockNumbfield " id='secdot'><span></span></div>
                        <div className="lockGridCol lockInputBox lockNumbfield " id='trddot'><span></span></div>
                        <div className="lockGridCol lockInputBox lockNumbfield " id='frtdot'><span></span></div>
                    </div>
                </div>

                <div id="lockNumbers">
                    {
                        !check ?
                            <>
                                <div className="lockgrid">
                                    <div className="lockGridCol lockNumbPad"><button >1</button></div>
                                    <div className="lockGridCol lockNumbPad"><button >2</button></div>
                                    <div className="lockGridCol lockNumbPad"><button >3</button></div>
                                </div>
                                <div className="lockgrid">
                                    <div className="lockGridCol lockNumbPad"><button >4</button></div>
                                    <div className="lockGridCol lockNumbPad"><button >5</button></div>
                                    <div className="lockGridCol lockNumbPad"><button >6</button></div>
                                </div>
                                <div className="lockgrid">
                                    <div className="lockGridCol lockNumbPad"><button >7</button></div>
                                    <div className="lockGridCol lockNumbPad"><button >8</button></div>
                                    <div className="lockGridCol lockNumbPad"><button >9</button></div>
                                </div>
                            </>
                            :
                            <>
                                <div className="lockgrid">
                                    <div className="lockGridCol lockNumbPad"><button onClick={fst}>1</button></div>
                                    <div className="lockGridCol lockNumbPad"><button onClick={sec}>2</button></div>
                                    <div className="lockGridCol lockNumbPad"><button onClick={trd}>3</button></div>
                                </div>
                                <div className="lockgrid">
                                    <div className="lockGridCol lockNumbPad"><button onClick={frt}>4</button></div>
                                    <div className="lockGridCol lockNumbPad"><button onClick={fif}>5</button></div>
                                    <div className="lockGridCol lockNumbPad"><button onClick={six}>6</button></div>
                                </div>
                                <div className="lockgrid">
                                    <div className="lockGridCol lockNumbPad"><button onClick={sev}>7</button></div>
                                    <div className="lockGridCol lockNumbPad"><button onClick={eit}>8</button></div>
                                    <div className="lockGridCol lockNumbPad"><button onClick={nin}>9</button></div>
                                </div>
                            </>
                    }
                    <div className="lockgrid">
                        <div className="lockGridCol lockNumbPad">
                            <div>
                            </div>
                        </div>
                        <div className="lockGridCol lockNumbPad"><button onClick={ten}>0</button>
                        </div>

                        <div className="lockGridCol lockNumbPad emptyLockNumbPad">
                            <button onClick={clear}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 827 827">
                                    <path
                                        d="M239.5 159c-2.8 1-6.8 2.6-9 3.8C225 165.6 20 370.9 16.7 377a42 42 0 0 0 .6 42.5c8.2 12 211.4 212.6 218.6 215.8 12.4 5.7 540.8 5 552.4-.7a46 46 0 0 0 19.3-19.1c4.7-9.7 5-424.3.4-434.4a42 42 0 0 0-26-22.5c-8.8-2.6-535.2-2.3-542.5.3m283.7 159.4-48.4 48.2-23.6-24c-13-13.3-34.6-34.9-48-48L379 270.6l-9 9c-5 4.9-11.8 12-15.1 15.7l-6.1 6.9 47.6 47.6 47.7 47.7-47.5 47.5a2503 2503 0 0 0-47.5 48.2 364 364 0 0 0 30.2 30.6c.5-.2 22.3-21.7 48.4-47.8l47.5-47.4 47.7 47.7a1673 1673 0 0 0 48.7 47.7c.6 0 7.7-6.7 16-15l14.9-15-23-23.1-48.2-48.3-25.3-25 47.8-47.7c26.3-26.3 47.9-48.1 48-48.6s-6.6-7.7-15-16L571.5 270z"
                                        fillRule="evenodd" />
                                    <path className="svgPathX"
                                        d="m523.2 318.3-48.4 48.2-23.6-24c-13-13.3-34.6-34.9-48-48L379 270.6l-9 9c-5 4.9-11.8 12-15.1 15.7l-6.1 6.9 47.6 47.6 47.7 47.7-47.5 47.5a2503 2503 0 0 0-47.5 48.2 364 364 0 0 0 30.2 30.6c.5-.2 22.3-21.7 48.4-47.8l47.5-47.4 47.7 47.7a1673 1673 0 0 0 48.7 47.7c.6 0 7.7-6.7 16-15l14.9-15-23-23.1-48.2-48.3-25.3-25 47.8-47.7c26.3-26.3 47.9-48.1 48-48.6s-6.6-7.7-15-16L571.5 270z"
                                        fillRule="evenodd" />
                                </svg>
                            </button>
                        </div>


                    </div>
                </div>

            </div>
            <Link className='passcode-reset-link' to={'/reset'}>
                <p>Reset your passcode</p>
            </Link>
        </div>
    )
}

export default Code