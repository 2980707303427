import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectinfo } from '../features/infoslice';
import axios from 'axios';

function Newsrchbar({ data, frndarr }) {

  axios.defaults.withCredentials = true

  const user = useSelector(selectinfo)
  const [srchd2, setsrchd2] = useState([])


  useEffect(() => {
    const arr = []
    if (frndarr) {
      frndarr?.map((vl) => {
        return arr.push({
          email: vl?.user_email,
          image: vl?.serial,
          name: vl?.username,
        });
      })
    }

    setsrchd2(arr)
  }, [frndarr])


  const [add, setadd] = useState('')

  const [preadd, setpreadd] = useState(false)


  useEffect(() => {
    const filter = srchd2.filter((val) => {
      return val?.email.includes(data?.user_email)
    })

    filter.map((m) => {
      return setadd(m?.email);
    })


  }, [data.user_email, srchd2])



  const addbtn = async () => {
    setpreadd(true)
    const combine_id = `${data?.user_name + '+' + user?.user?.username}`


    try {

      const values = {
        full_name: data?.full_name,
        user_name: data?.user_name,
        user_id: user?.user?.username,
        user_email: data?.user_email,
        image: data?.serial,
        friend_id: data?.user_name,
        combine_id: combine_id,
        country: data?.user_country,
        usertype: data?.user_type,
        btcaddress: data?.btc_address,
        ethaddress: data?.eth_address,
        noftification: 0
      }

      await axios.post('https://app.wikiescrow.org/escrow/includes/addFriend.inc.php', values).then(async (response) => {
        if (response?.data.replyStatus === 'Successful') {
          toast.success('ADDED SUCCESSFULLY')

          const em = data?.user_email

          const value = {
            em: em
          }

          await axios.post('https://app.wikiescrow.org/escrow/mail/add.php', value).then((response) => {
            console.log(em);
            console.log(response);

          })
        }
      })



    } catch (e) {
      console.log(e);
    }
  }





  return (
    <>
      <div>
        {
          data.user_email === add ?
            <div className='sidebars'>
              <div className='avatarbod'>
                <div className={`svg${data?.serial % 10}`}></div>
              </div>
              <div className='sidebarwrt'>
                <h3>{data?.full_name}</h3>
                <p>@{data?.user_name}</p>
              </div>

              <button className='added'>ADDED</button>
            </div>
            :


            <>
              {
                data?.user_email !== user?.user?.email ?
                  <div className='sidebars'>
                    <div className='avatarbod'>
                      <div className={`svg${data?.serial % 10}`}></div>
                    </div>
                    <div className='sidebarwrt'>
                      <h3>{data?.full_name}</h3>
                      <p>@{data?.user_name}</p>
                    </div>

                    {
                      preadd ?
                        <button className='adding' disabled>ADDING</button>
                        :
                        <button onClick={addbtn} className='add'>ADD</button>
                    }
                  </div>
                  :
                  null
              }
            </>
        }
      </div>
    </>
  )
}

export default Newsrchbar