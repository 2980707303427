import React, { useEffect, useState } from 'react'
import Header from '../Deskchat.js/Header'
import { motion } from 'framer-motion'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'


function About({ setvbar }) {

    useEffect(() => {
        setvbar(false)
    }, [])

    const about = true

    const navigate = useNavigate();

    const [detectdesk, setdetectdesk] = useState()
    const [detectand, setdetectand] = useState()
    const [detectios, setdetectios] = useState()


    useEffect(() => {
        // Check if the device is a laptop
        setdetectdesk(window.matchMedia('(min-width: 999px)').matches)

        // Check if the device is an Android device
        setdetectand(/Android/i.test(navigator.userAgent))

        // Check if the device is an iOS device
        setdetectios(/iPhone|iPad|iPod/i.test(navigator.userAgent))

        var isMacOS = navigator.userAgent.match(/Macintosh|Mac\s+OS\s+X/i) !== null;

        // Check if isMacOS is true
        if (isMacOS) {
            // Check if the browser supports touch events
            var isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

            // Check if isTouchScreen is true
            if (isTouchScreen) {
                // Device likely iPad Air
                setdetectios(true)
            }

        }
    }, [])



    const func1 = () => {
        console.log('func1');
    }

    const func2 = () => {
        console.log('func2');
    }





    return (
        <div className={'setting'} >
            {/* darkback */}
            <Header abt={about} />
            {/* <motion.div animate={{ y: -30, scale: 1 }} initial={{scale:0}}  className='registerbodx notboxx'> */}
            <motion.div className='settingbod'>
                <div className='settingbox sett'>
                    <div className='settingboxhd'>
                        <div >
                            <h2>About us</h2>
                        </div>
                    </div>

                    <div className='settingboxnot'>
                        <h3>Personal Information</h3>

                        <div onClick={func1} className='logoutbtn'>
                            <h3>FUNC1</h3>
                        </div>

                        <div onClick={func2} className='logoutbtn'>
                            <h3>FUNC2</h3>
                        </div>




                        <div className={'settingslink'} ></div>

                    </div>
                </div>
            </motion.div>

        </div>
    )
}

export default About