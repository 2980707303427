import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

function Reset() {

    const Reset = () => {
        window.history.back();
    }


    axios.defaults.withCredentials = true
    const [loader, setloader] = useState(false)

    const logout = async () => {
        setloader(true)
        try {
            const response = await axios.get('https://app.wikiescrow.org/escrow/includes/logout.inc.php');
            if (response.data.serverReplyText === "Successful") {
                window.location.replace('https://app.wikiescrow.org/escrow/index.php')
            }
            localStorage.removeItem('pincode')
            localStorage.removeItem('trials')
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }




    const [detectdesk, setdetectdesk] = useState()
    const [detectand, setdetectand] = useState()
    const [detectios, setdetectios] = useState()


    useEffect(() => {
        // Check if the device is a laptop
        setdetectdesk(window.matchMedia('(min-width: 999px)').matches)

        // Check if the device is an Android device
        setdetectand(/Android/i.test(navigator.userAgent))

        // Check if the device is an iOS device
        setdetectios(/iPhone|iPad|iPod/i.test(navigator.userAgent))

        var isMacOS = navigator.userAgent.match(/Macintosh|Mac\s+OS\s+X/i) !== null;

        // Check if isMacOS is true
        if (isMacOS) {
            // Check if the browser supports touch events
            var isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

            // Check if isTouchScreen is true
            if (isTouchScreen) {
                // Device likely iPad Air
                setdetectios(true)
            }

        }
    }, [])


    const navigate = useNavigate()

    const back = () => {
        navigate('/')
    }

    return (
        <div >
            <div className="qr-header">
                <div id="returnqrbtn" className="qr-backlink press" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {
                        detectand ?
                            <div onClick={Reset} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className='arrowBackContainer'>
                                <svg xmlns="http://www.w3.org/2000/svg" className='androidBackArrow' viewBox="0 0 391 391"><path d="m96.5 100-96 96 96.2 96.2 96.3 96.3 16.5-16.5c9-9 16.5-17 16.5-17.5s-30-31-66.7-67.7L92.5 220H385v-48H92.5l66.8-66.8A3409 3409 0 0 0 226 37.5c0-1-32-33.5-33-33.5-.3 0-43.7 43.2-96.5 96" fillRule="evenodd" /></svg>
                            </div>
                            :
                            <div onClick={Reset} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className='arrowBackContainer'>
                                <svg xmlns="http://www.w3.org/2000/svg" className='iosBackArrow' viewBox="0 0 924 924"><path d="M490.9 12a87 87 0 0 0-28.4 10.2c-6.2 3.5-29.5 26.5-202.8 200.1C137.3 345 62 421.1 59 425.5a74 74 0 0 0-15 37 74 74 0 0 0 14.8 54.8c2.5 3.1 66 67.5 141.2 143s159.7 160.3 187.7 188.6 54.2 54 58.3 57.3a79 79 0 0 0 26.4 14.3c3.7 1.2 6.5 2.4 6.2 2.7s5.7.6 13.3.7a51 51 0 0 0 22.3-2.5 81 81 0 0 0 35-20.4 72 72 0 0 0 22.6-50.7 71 71 0 0 0-10.1-42.7c-5.6-9.3-10-14.2-46-50.6a95746 95746 0 0 1-282.9-287.6c-.4-.8-.4-2.2.2-3.1.5-1 8.7-9.4 18.3-18.7l71.3-70.5 235.8-233.9a81 81 0 0 0 19-31.7c3.1-9.4 3.6-29.6.8-40.6a81 81 0 0 0-26.4-42 99 99 0 0 0-30-15.3c-7.5-2-22.6-2.7-30.8-1.5" fillRule="evenodd" /></svg>
                            </div>
                    }
                </div>

                <div className="qr-header-title ethereum-qr-title">
                    Reset passcode
                </div>

                <div className="qrEmptBar"></div>
            </div>

            <div id="page-top-bar">
                <div className="mbheader">
                    <div className="logtextbox">
                        <span className="staketoken-title">
                        </span>
                    </div>
                </div>
            </div>

            <div className="page-content" style={{ display: "flex", position: "relative", paddingTop: "0", flexDirection: "column", alignItems: "center", justifyContent: "center", minHeight: "calc(100vh - 65px)" }}>
                <div style={{ width: "100%", minHeight: "calc(100vh - 120px)", position: "relative", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div className="qr-warning resetLockWarning qr-warning1 qr-warning4">
                            <svg xmlns="http://www.w3.org/2000/svg" style={{ flexShrink: "0" }} viewBox="0 0 24 24"><path clipRule="evenodd" d="M13 4.4a1 1 0 0 0-2 0L3.8 18.5c-.4.8.2 1.7 1 1.7H19c1 0 1.5-.9 1-1.7zm-3.7-.8a3 3 0 0 1 5.3 0l7 14.1A3 3 0 0 1 19 22H5a3 3 0 0 1-2.7-4.3z" fill="#ffbe00" fillRule="evenodd" /><path d="M12 8v5m0 3v.5" stroke="#ffbe00" strokeLinecap="round" strokeWidth="1.7" /></svg>
                            <div>
                                <span>WARNING: &nbsp;</span>No need to panic. If you've forgotten your passcode, you need to sign out and sign back in. After that, you'll be prompted to create a new password.
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ width: "90%", margin: "0 auto", flexShrink: "0", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {
                        loader ?
                            <div className='logoutbtn'>
                                {
                                    detectios ?
                                        <div className="floatingBarsBase loader34">
                                            <div className="floatingBarsG">
                                                <div className="blockG" id="rotateG_01"></div>
                                                <div className="blockG" id="rotateG_02"></div>
                                                <div className="blockG" id="rotateG_03"></div>
                                                <div className="blockG" id="rotateG_04"></div>
                                                <div className="blockG" id="rotateG_05"></div>
                                                <div className="blockG" id="rotateG_06"></div>
                                                <div className="blockG" id="rotateG_07"></div>
                                                <div className="blockG" id="rotateG_08"></div>
                                            </div>
                                        </div>
                                        : null
                                }


                                {
                                    detectand ?
                                        <>
                                            <div className='loader loader34'>
                                                <svg className="circular" viewBox="25 25 50 50">
                                                    <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10" />
                                                </svg>
                                            </div>
                                        </>
                                        : null
                                }
                            </div>
                            :

                            <button id="approveverify" onClick={logout} className="approvenow approvenowone joinnow formy-form__button buttongrey press">
                                <span id="approveverifytext">I understand, Sign out</span>
                            </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default Reset