import React, { useEffect, useState } from 'react';
import './App.css';
import Mobchat from './Mochat.js/Mobchat';
import Loader from './Loader';
import Redloader from './Redloader';
import { useMediaQuery } from '@mui/material';
import axios from 'axios';


function App() {


  // THEME
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');


  const Col = () => {
    const element = document.getElementById('light');
    const bll = localStorage.getItem('theme')
    if (prefersDarkMode) {
      element.classList.remove('light');
      element.classList.add('dark');
    } else if (!prefersDarkMode) {
      element.classList.remove('dark');
    }
  }

  Col()








  // checking auth starts //

  axios.defaults.withCredentials = true



  // checking auth starts //

  const [data, setData] = useState(null);
  const [dataemail, setDataemail] = useState(null);

  useEffect(() => {

    const fetchData = async () => {
      try {
        await axios.post('https://app.wikiescrow.org/escrow/logStatus/userState.php').then((response) => {
          if (response.status === 200) {
            setData(response.data);
            setDataemail(response.data.email);

          } else {
          }
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }, []);

  // console.log(data);











  // loading starts

  const [load, setload] = useState()

  useEffect(() => {
    setTimeout(() => {
      setload(false)
    }, 2000)

    clearTimeout(setload(true))
  }, [])

  // loading ends








  // theme function starts //



  const [detectdesk, setdetectdesk] = useState()
  const [detectand, setdetectand] = useState()
  const [detectios, setdetectios] = useState()


  const func = () => {

    // Check if the device is a laptop
    setdetectdesk(window.matchMedia('(min-width: 999px)').matches)

    // Check if the device is an Android device
    setdetectand(/Android/i.test(navigator.userAgent))

    // Check if the device is an iOS device
    setdetectios(/iPad|iPhone|iPod/i.test(navigator.userAgent) && !window.MSStream)



    var isMacOS = navigator.userAgent.match(/Macintosh|Mac\s+OS\s+X/i) !== null;

    // Check if isMacOS is true
    if (isMacOS) {
      // Check if the browser supports touch events
      var isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

      // Check if isTouchScreen is true
      if (isTouchScreen) {
        // Device likely iPad Air
        setdetectios(true)
      }

    }


    const element = document.getElementById('light');

    if (detectios) {
      element.classList.add('iosview');
    } else if (detectand) {
      element.classList.add('androidView');
    }
  }

  setInterval(() => {
    func()
  }, 1000);


  const [tell, settell] = useState()


  const funcc = () => {

    const url = window.location.hash

    const slice = url.slice(7)

    if (slice === process.env.REACT_APP_API_KEY2) {
      localStorage.setItem('ride_on', true)
      settell(true)
    }

  }


  const funcc2 = () => {
    const rd = localStorage.getItem('ride_on')

    if (!rd) {
      settell(false)
    } else {
      settell(true)
    }
  }

  useEffect(() => {
    funcc()
    funcc2()
  }, [])



  return (
    <>
      {
        !load ?
          tell ?
            data?.userStatus === 'online' || dataemail ?
              detectand || detectios ?
                <>
                  <Mobchat detectand={detectand} detectdesk={detectdesk} detectios={detectios} setload={setload} data={data} />
                </>
                :
                null
              :
              <Redloader detectand={detectand} detectdesk={detectdesk} detectios={detectios} data={data} />
            :
            null
          :
          <Loader detectand={detectand} detectdesk={detectdesk} detectios={detectios} />
      }
    </>
  );
}

export default App;
