import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectinfo } from './features/infoslice'
import axios from 'axios'
// import { useSelector } from 'react-redux'
// import { selectinfo } from './features/infoslice'

function Bottom({ ref2, setref2 }) {

    // const info = useSelector(selectinfo)











    const reff = useRef()


    const [ref1, setref1] = useState('0')


    const user = useSelector(selectinfo)

    useEffect(() => {
        setref1(localStorage.getItem('pos'))
        setref2(localStorage.getItem('pos1'))

        reff.current.style.left = `${ref2}%`
    }, [ref2])


    const fstclick = () => {
        localStorage.setItem('pos', 0)
        localStorage.setItem('pos1', 0)
        reff.current.style.left = '0'
        setref1('0')
        setTimeout(() => {
        }, 50);

    }

    const secclick = async () => {
        localStorage.setItem('pos', 1)
        localStorage.setItem('pos1', 25)
        reff.current.style.left = '25%'
        setref1('1')
        setTimeout(() => {
        }, 50);
    }

    const trdclick = () => {
        localStorage.setItem('pos', 2)
        localStorage.setItem('pos1', 50)
        reff.current.style.left = '50%'
        setref1('2')
        setTimeout(() => {
        }, 50);
    }

    const frtclick = async () => {
        localStorage.setItem('pos', 3)
        localStorage.setItem('pos1', 75)
        reff.current.style.left = '75%'
        setref1('3')
        // setnum1(0)
        try {
            const response = await axios.post('https://app.wikiescrow.org/escrow/includes/update-notifications-alert.inc.php');
            //console.log(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const [data, setData] = useState(0);

    axios.defaults.withCredentials = true

    useEffect(() => {
        const fetchData = async () => {
            try {
                await axios.post('https://app.wikiescrow.org/escrow/includes/check-notifications.inc.php').then((response) => {
                    setData(response.data);
                });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        setInterval(() => {
            fetchData();
        }, 2000);
    }, []);






    return (
        <div className={'bottom bottomBarHeight bttm1'}>
            {/* darkbottom */}
            <div className='bottombod'>
                {
                    ref1 === '0' ?
                        <Link onClick={fstclick} to='/' className={'bottomlinks'} >
                            <svg xmlns="http://www.w3.org/2000/svg" id='bttmmicons' viewBox="0 0 733 733">
                                <path d="M357 42.5c-5.6 1.8 1.5-4-125.6 102.5L71.5 278.9a5659 5659 0 0 0-56.3 47.5 27 27 0 0 0-.3 39.4 28 28 0 0 0 28.9 5.4c2.5-1 13.6-9.5 27.2-21A709 709 0 0 1 94.4 331c.3 0 .7 63.8.8 141.8l.3 141.7 2.8 9.4a112 112 0 0 0 79.7 78.2l8.5 2.4 173.5.3c111 .2 176.2 0 181-.7a108 108 0 0 0 60.4-29.1c16-15.3 26-32 31.8-54l2.2-8.5.6-140.8.5-140.9 23 19.3a296 296 0 0 0 27 21 30 30 0 0 0 22-.5c6-2.8 11.6-8.7 14-15a31 31 0 0 0-1.8-23c-2-3.3-15.8-15.4-56.8-49.7l-92.4-77.4-114.2-95.7A2571 2571 0 0 0 376.9 44a29 29 0 0 0-19.9-1.5M256.8 194.3l-107.7 90.2.2 161.5.2 161.5 3 7c1.5 3.9 4 8.8 5.3 11a63 63 0 0 0 21 18.8c11.2 6 16.7 6.7 49 6.7H256V433l2-4.3a31 31 0 0 1 12.5-13l4-2.2 89-.3c97.9-.3 94-.5 101.8 5.9 2 1.7 5 5.2 6.5 7.7l2.7 4.7.3 109.8.2 109.7h28.4c31.6 0 38-1 49.5-7a60 60 0 0 0 26.4-32l2.2-6.5.3-160.1.2-160.2-38.7-32.5L435 162.1c-38.2-32-69.7-58-70-58s-49 40.6-108.3 90.2M310 559v92h111V467H310z" fillRule="evenodd" />
                            </svg>
                            <span className='bottomBarText'>Home</span>
                        </Link>
                        :
                        <Link onClick={fstclick} to='/' className={'bottomlinksL'} >
                            <svg xmlns="http://www.w3.org/2000/svg" id='bttmmicons' viewBox="0 0 733 733">
                                <path d="M357 42.5c-5.6 1.8 1.5-4-125.6 102.5L71.5 278.9a5659 5659 0 0 0-56.3 47.5 27 27 0 0 0-.3 39.4 28 28 0 0 0 28.9 5.4c2.5-1 13.6-9.5 27.2-21A709 709 0 0 1 94.4 331c.3 0 .7 63.8.8 141.8l.3 141.7 2.8 9.4a112 112 0 0 0 79.7 78.2l8.5 2.4 173.5.3c111 .2 176.2 0 181-.7a108 108 0 0 0 60.4-29.1c16-15.3 26-32 31.8-54l2.2-8.5.6-140.8.5-140.9 23 19.3a296 296 0 0 0 27 21 30 30 0 0 0 22-.5c6-2.8 11.6-8.7 14-15a31 31 0 0 0-1.8-23c-2-3.3-15.8-15.4-56.8-49.7l-92.4-77.4-114.2-95.7A2571 2571 0 0 0 376.9 44a29 29 0 0 0-19.9-1.5M256.8 194.3l-107.7 90.2.2 161.5.2 161.5 3 7c1.5 3.9 4 8.8 5.3 11a63 63 0 0 0 21 18.8c11.2 6 16.7 6.7 49 6.7H256V433l2-4.3a31 31 0 0 1 12.5-13l4-2.2 89-.3c97.9-.3 94-.5 101.8 5.9 2 1.7 5 5.2 6.5 7.7l2.7 4.7.3 109.8.2 109.7h28.4c31.6 0 38-1 49.5-7a60 60 0 0 0 26.4-32l2.2-6.5.3-160.1.2-160.2-38.7-32.5L435 162.1c-38.2-32-69.7-58-70-58s-49 40.6-108.3 90.2M310 559v92h111V467H310z" fillRule="evenodd" />
                            </svg>
                            <span className='bottomBarText'>Home</span>
                        </Link>
                }

                {
                    ref1 === '1' ?
                        <Link onClick={secclick} to='/chat' className={'bottomlinks'} >

                            <svg viewBox="2 2 20 20" id='bttmmicons' xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.2 7.6a.8.8 0 0 0-1-1.2Q3.4 9 3.2 13a.8.8 0 0 0 1.5 0q.1-3.4 2.5-5.4m10.6-1.2a.7.7 0 0 0-1 1.2 7 7 0 0 1 2.4 5.4.8.8 0 0 0 1.6 0q-.1-4-3-6.6M10.2 20a.7.7 0 1 0-.4 1.5 9 9 0 0 0 4.4 0 .7.7 0 1 0-.4-1.5 7 7 0 0 1-3.6 0"
                                    opacity=".5" />
                                <path
                                    d="M9 6a3 3 0 1 0 6 0 3 3 0 0 0-6 0M2.5 18a3 3 0 1 0 6 0 3 3 0 0 0-6 0m16 3a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                            </svg>

                            {user?.user?.notification > 0 ?
                                <span className='notification-dot'>{user?.user?.notification}</span>
                                : null
                            }
                            <span className='bottomBarText'>Escrow</span>
                        </Link>
                        :
                        <Link onClick={secclick} to='/chat' className={'bottomlinksL'} >
                            <svg viewBox="2 2 20 20" id='bttmmicons' xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.2 7.6a.8.8 0 0 0-1-1.2Q3.4 9 3.2 13a.8.8 0 0 0 1.5 0q.1-3.4 2.5-5.4m10.6-1.2a.7.7 0 0 0-1 1.2 7 7 0 0 1 2.4 5.4.8.8 0 0 0 1.6 0q-.1-4-3-6.6M10.2 20a.7.7 0 1 0-.4 1.5 9 9 0 0 0 4.4 0 .7.7 0 1 0-.4-1.5 7 7 0 0 1-3.6 0"
                                    opacity=".5" />
                                <path
                                    d="M9 6a3 3 0 1 0 6 0 3 3 0 0 0-6 0M2.5 18a3 3 0 1 0 6 0 3 3 0 0 0-6 0m16 3a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                            </svg>

                            {user?.user?.notification > 0 ?
                                <span className='notification-dot'>{user?.user?.notification}</span>
                                : null
                            }
                            <span className='bottomBarText'>Escrow</span>
                        </Link>
                }

                {
                    ref1 === '2' ?
                        <Link onClick={trdclick} to='/adduser' className={'bottomlinks'} >
                            <svg xmlns="http://www.w3.org/2000/svg" id='bttmmicons' viewBox="0 0 837 837">
                                <path
                                    d="M393 2.6a191 191 0 0 0-89.5 31.9 187 187 0 0 0-83.2 151A185 185 0 0 0 273 322.4q40.3 41.9 99 54.4c11.6 2.5 14.2 2.6 37 2.6s25.4-.1 37-2.6a190 190 0 0 0 132.4-101.6c14-27.8 20.3-57.3 19.3-89.7A189 189 0 0 0 439 4.5c-9.2-1.6-38.9-2.8-46-1.9m-2.5 76.5a113.5 113.5 0 0 0-24 217.4c14.4 6 25 7.9 42.5 7.9s28-2 42.6-8c20-8.1 39.7-23.9 51-40.9a115 115 0 0 0 16.2-93A114 114 0 0 0 426.7 79c-7.3-1.2-29-1.1-36.2 0M372 420.7A379 379 0 0 0 32.5 759.9c-2 19.6-2 43.4-.2 48.6 4 11 10.7 18.3 21.4 23.2 4.9 2.3 7.2 2.8 14.4 2.8 7.9 0 9-.3 16.1-3.8a35 35 0 0 0 18-18.4c2.8-5.9 3-7.2 3.8-24.5a303 303 0 0 1 221.6-282.4 283 283 0 0 1 81.4-10.7c52.3 0 96.8 11.1 143 35.6A303 303 0 0 1 695.2 698a314 314 0 0 1 16.8 90.6c0 11.7 1.3 18.4 5 26a35 35 0 0 0 17.9 16.8 27 27 0 0 0 15.1 3c7.2 0 9.4-.4 14.5-2.7a32 32 0 0 0 16-13.1c6-9.1 6.7-12.2 6.6-29.2a379 379 0 0 0-342.4-368.9 490 490 0 0 0-72.7 0M402.2 571a38 38 0 0 0-30.3 32.3 441 441 0 0 0-.9 34V665h-30.3c-34.1 0-38.2.6-47.3 6.6a35 35 0 0 0-16.2 33.7 35 35 0 0 0 16.3 28.2c9.4 6.2 11.4 6.5 46.3 6.5H371v27.8c0 15.2.4 30.5 1 34a37 37 0 0 0 21.8 29.5c5.9 2.8 7.7 3.2 15.3 3.2a26 26 0 0 0 16.1-3.8 35 35 0 0 0 18.2-18.7l3.1-6.5.3-32.7.4-32.8h31.2c26.6 0 32-.2 36.2-1.7 10.8-3.7 19-11 23.7-21a47 47 0 0 0 0-29.6 40 40 0 0 0-14.5-16.7c-8.8-5.5-13-6-46.4-6H447l-.3-32.2c-.3-30.7-.5-32.6-2.6-38a37 37 0 0 0-21.8-21.7 41 41 0 0 0-20.2-2"
                                    fillRule="evenodd" />
                            </svg>
                            <span className='bottomBarText'>Add user</span>
                        </Link>
                        :
                        <Link onClick={trdclick} to='/adduser' className={'bottomlinksL'} >
                            <svg xmlns="http://www.w3.org/2000/svg" id='bttmmicons' viewBox="0 0 837 837">
                                <path
                                    d="M393 2.6a191 191 0 0 0-89.5 31.9 187 187 0 0 0-83.2 151A185 185 0 0 0 273 322.4q40.3 41.9 99 54.4c11.6 2.5 14.2 2.6 37 2.6s25.4-.1 37-2.6a190 190 0 0 0 132.4-101.6c14-27.8 20.3-57.3 19.3-89.7A189 189 0 0 0 439 4.5c-9.2-1.6-38.9-2.8-46-1.9m-2.5 76.5a113.5 113.5 0 0 0-24 217.4c14.4 6 25 7.9 42.5 7.9s28-2 42.6-8c20-8.1 39.7-23.9 51-40.9a115 115 0 0 0 16.2-93A114 114 0 0 0 426.7 79c-7.3-1.2-29-1.1-36.2 0M372 420.7A379 379 0 0 0 32.5 759.9c-2 19.6-2 43.4-.2 48.6 4 11 10.7 18.3 21.4 23.2 4.9 2.3 7.2 2.8 14.4 2.8 7.9 0 9-.3 16.1-3.8a35 35 0 0 0 18-18.4c2.8-5.9 3-7.2 3.8-24.5a303 303 0 0 1 221.6-282.4 283 283 0 0 1 81.4-10.7c52.3 0 96.8 11.1 143 35.6A303 303 0 0 1 695.2 698a314 314 0 0 1 16.8 90.6c0 11.7 1.3 18.4 5 26a35 35 0 0 0 17.9 16.8 27 27 0 0 0 15.1 3c7.2 0 9.4-.4 14.5-2.7a32 32 0 0 0 16-13.1c6-9.1 6.7-12.2 6.6-29.2a379 379 0 0 0-342.4-368.9 490 490 0 0 0-72.7 0M402.2 571a38 38 0 0 0-30.3 32.3 441 441 0 0 0-.9 34V665h-30.3c-34.1 0-38.2.6-47.3 6.6a35 35 0 0 0-16.2 33.7 35 35 0 0 0 16.3 28.2c9.4 6.2 11.4 6.5 46.3 6.5H371v27.8c0 15.2.4 30.5 1 34a37 37 0 0 0 21.8 29.5c5.9 2.8 7.7 3.2 15.3 3.2a26 26 0 0 0 16.1-3.8 35 35 0 0 0 18.2-18.7l3.1-6.5.3-32.7.4-32.8h31.2c26.6 0 32-.2 36.2-1.7 10.8-3.7 19-11 23.7-21a47 47 0 0 0 0-29.6 40 40 0 0 0-14.5-16.7c-8.8-5.5-13-6-46.4-6H447l-.3-32.2c-.3-30.7-.5-32.6-2.6-38a37 37 0 0 0-21.8-21.7 41 41 0 0 0-20.2-2"
                                    fillRule="evenodd" />
                            </svg>
                            <span className='bottomBarText'>Add user</span>
                        </Link>
                }

                {
                    ref1 === '3' ?
                        <Link onClick={frtclick} to='/notification' className={'bottomlinks'} >
                            <svg viewBox="2 2 21 21" id='bttmmicons' xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M8 5.2q.8-.7 2-1V4a2 2 0 1 1 4 .2q1.2.3 2 1 1.4 1.1 1.8 2.7a12 12 0 0 1 .6 4v1q.4 2.1.8 3.3t1.5 2A1 1 0 0 1 20 20h-6.3q.3.4.3 1a2 2 0 1 1-3.7-1H4a1 1 0 0 1-.7-1.7q1-1 1.5-2.1a13 13 0 0 0 .8-4.2v-1.2q0-1.3.6-2.9T8 5.2M6 18l.6-1a15 15 0 0 0 1-4.9V12l.5-3.3q.3-1.2 1.2-1.8Q10 6 12 6t2.7.8q.9.6 1.2 1.8.4 1.1.4 2.4v1l.1.1v1A15 15 0 0 0 18 18z" />
                            </svg>

                            {data?.serverReplyText ?
                                <span className='notification-dot'>{data?.serverReplyText}</span>
                                :
                                null
                            }
                            <span className='bottomBarText'>Notifications</span>
                        </Link>
                        :
                        <Link onClick={frtclick} to='/notification' className={'bottomlinksL'} >
                            <svg viewBox="2 2 21 21" id='bttmmicons' xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M8 5.2q.8-.7 2-1V4a2 2 0 1 1 4 .2q1.2.3 2 1 1.4 1.1 1.8 2.7a12 12 0 0 1 .6 4v1q.4 2.1.8 3.3t1.5 2A1 1 0 0 1 20 20h-6.3q.3.4.3 1a2 2 0 1 1-3.7-1H4a1 1 0 0 1-.7-1.7q1-1 1.5-2.1a13 13 0 0 0 .8-4.2v-1.2q0-1.3.6-2.9T8 5.2M6 18l.6-1a15 15 0 0 0 1-4.9V12l.5-3.3q.3-1.2 1.2-1.8Q10 6 12 6t2.7.8q.9.6 1.2 1.8.4 1.1.4 2.4v1l.1.1v1A15 15 0 0 0 18 18z" />
                            </svg>

                            {data?.serverReplyText ?
                                <span className='notification-dot'>{data?.serverReplyText}</span>
                                :
                                null
                            }
                            <span className='bottomBarText'>Notifications</span>
                        </Link>
                }



                <div ref={reff} className='tube'>
                    <div className="tubelight">
                        <div className="light-ray"></div>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default Bottom