import { WifiOff } from '@mui/icons-material'
import React from 'react'
import { Detector } from 'react-detect-offline'

function Maxconnect(props) {
    return (
        <>
            <Detector
                render={({ online }) => (
                    online ?
                        props.children
                        :
                        <div className='emptcont'>
                            <WifiOff style={{ width: '6em', height: '6em' }} />
                            <h3>offline</h3>
                        </div>
                )}
            />
        </>
    )
}

export default Maxconnect